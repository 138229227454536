@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&family=Roboto&family=Changa+One&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {

  html, body {
    font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    overflow-x: clip;
  }

  nav {
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
  }
  main {
    font-size: 1.2rem;
  }

  footer {
    box-shadow: 0 -10px 25px 5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    background-color: var(--footer-color);
  }

  p {
    margin-bottom: 0.5rem;
  }

  .changa {
    font-family: 'Changa One', sans-serif;
  }


  h1 {
    font-family: 'Changa One', sans-serif;
    margin-bottom: 1rem;
    @apply text-5xl md:text-6xl;
  }

  h2 {
    font-family: 'Changa One', sans-serif;
    margin-bottom: 1rem;
    @apply text-3xl md:text-4xl;
  }

  a {
    color: #e6007e;
  }

  blockquote {
    font-size: 120%;
    @apply mb-2;
  }

  .headline {
    background: linear-gradient(
      to right,
      #e6007e 0%,
      #e6007e calc((100vw - 64rem)/2),
      transparent calc((100vw - 64rem)/2),
      transparent 100%
    );
  }

  .headline h1 {
    margin: 0;
    display: inline-block;
    height: 100%;
    background-color: #e6007e;
    padding: 0.05em 0.2em;
    color: white;
    text-transform: uppercase;

    @apply text-4xl md:text-5xl 2xl:text-6xl;

    @media (max-width: 1279px) {
      position: relative;
      padding-left: 4rem;
      left: 0;
    }

  }

  .dateline {
    background: linear-gradient(
      to right,
      #fff 0%,
      #fff calc((100vw - 64rem)/2),
      transparent calc((100vw - 64rem)/2),
      transparent 100%
    );
    date {
      margin: 0;
      display: inline-block;
      border-left: 1px #fff solid;
      font-family: 'Changa One', sans-serif;
      height: 100%;
      background-color: #fff;
      padding: 0.05em 0.5em;
      color: black;
      text-transform: uppercase;

      @apply text-3xl md:text-4xl ;

      @media (max-width: 1279px) {
        position: relative;
        padding-left: 4rem;
        left: 0;
      }
    }
  }

  img.fade {
    mask-image: linear-gradient(to right, transparent 0%, rgb(0, 0, 0) 5%, rgb(0, 0, 0) 95%, transparent 100%);
  }


  .logo {
    font-family: 'Changa One', sans-serif;
    color: black;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    /*position: absolute;*/
    /*transform: rotate(-5deg);*/
    /*top: 1rem;*/

    span {
      padding-left: 3rem;
      left: -3rem;
      padding-right: 0.5rem;
      position: relative;
      width: max-content;
      line-height: 1;
    }

    .topline {
      color: black;
      background-color: white;
      font-weight: 300;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }

    .main {
      font-weight: 500;

      color: var(--logo-main-font-color);
      background-color: var(--logo-main-background-color);
      text-transform: uppercase;
      margin-bottom: 0.5rem;
      padding-left: 2.6rem;
      padding-right: 1rem;
    }

    .subline {
      color: var(--logo-subline-font-color);
      background-color: var(--logo-subline-background-color);
      font-weight: 300;
      text-transform: uppercase;
      padding-left: 2.6rem;
    }


  }

  .k-icon {
    display: inline-block;
    @apply p-1 mr-2;
    svg {
      width: 2rem;
      height: 2rem;
    }

    &.instagram {
      background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)
    }

    &.website {
      background-color: #e6007e;
    }

  }

  .content-text ul {
    list-style: disc;
    padding-left: 2rem;
  }

  .neos-contentcollection form {
    @apply mx-8 md:mx-16 lg:mx-auto lg:max-w-4xl text-lg;
    input, textarea {
      width: 100%;
      border: 1px solid black;
      padding: 0.5rem;
    }
    label {
      margin-top: 1rem;
      display: block;
    }
    .submit {
      text-align: right;
      button {
        padding: 0.5rem 1rem;
        background-color: #e6007e;
        color: white;
      }
    }

  }

  a.external-link svg {
    display: inline;
    margin-left: 0.5rem;
    bottom: 3px;
    position: relative;
  }

  .image-number-field input[type=number] {
      max-width: 4rem;
  }

}


